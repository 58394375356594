table {
    width: 100%;
    min-height: 70vh;
    border-spacing: 0px;
    font-size: 1.1em;
}

.secondrow {
    background-color: #eaeef1;
}

tr {
    th {
        text-align: center;
    }
}

td {
    text-align: center;
    min-width: 10%;
}

tr {
    padding: 0.4em;
}

.align-left {
    text-align: left;
    padding-left: 10px;
}

p{
    margin: 0;
}

.center {
    text-align: center;
}