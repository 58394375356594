@import './assets/styles/prayertimes.scss';
@import './assets/styles/membership.scss';

* { padding: 0; margin: 0; }
html, body, #fullheight {
    min-height: 100% !important;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
}

.page2-logo{
    position: fixed;
    height: 5vh;
    right: 5vh;
    top: 4vh;
}

.col-100 {
    height: 100vh;
}

.col-50 {
    height: 50vh;
}

.col-25 {
    height: 25vh;
}

.timerCircle {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 100;
}

h1 {
    font-size: 15em;
}

h2 {
    font-size: 10em!important;
}

h3 {
    font-size: 8em!important;
}

h1, h2, h3{
    padding: 0px;
    margin: 0px;
}

h4 {
    font-size: 2.5rem;
}

h5 {
    font-size: 2rem;
}

.flyer {
    height: 100vh;
    width: auto;
    max-width: 50vw;
}

.page1-logo{
    vertical-align: middle;

    img {
        position: absolute;
        top: 20vh;
        left: 50%;
        width: 20vw;
        margin-left: -200px; 
    }
}

/* 

p
.flyer1{
    grid-column: 1 / 2;
    grid-row: 3 / 5;
    margin: auto;
}


.content {
    padding: 2em;
}

.flyers {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    
    .flyer {
        width: 25%;
        height: 350px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
    
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}


//right fixed counter

.h50 {
    height: 50vh;
}

.clock-center{
    padding-top: 7vh;
}


strong {
    font-weight: 800;
    font-size: 1.2em;
}


.fullcenter-content {
    text-align: center;
}

.reset-button {
    font-size: 3vh!important;
}

.home {
    text-align: center;
    padding-top: 4vh;
    font-size: 4vh!important;
}

.prayertime-info {
    text-align: center;
    font-size: 1em;
    padding-bottom: 1vh;
    font-weight: 500;
}

.clock_logo {
    margin: 20%;
}
*/