
.prayertime {
    height: 100%;
}

.times {
    padding-top: 1vh;
}

.time {
    height: 6.5vh;
    position: relative;
}

.time {
    background-color: white;
    margin-bottom: 0.2vh;
    margin-top: 0.2vh;
    margin-left: 5vw;
    margin-right: 5vw;
}

.time-content {
    padding: 1vh;
    padding-left: 2vh;
}

.prayer-name {
    font-size: 1.5vh;
    font-weight: 700;
}

.prayer-time {
    font-size: 2.5vh;
    font-weight: 300;
}

.selected .prayer-name{
    font-size: 2vh;
    font-weight: 700;
}

.selected .prayer-time{
    font-size: 4.5vh;
    font-weight: 300;
}

.selected {
    height: 10vh;
    background-color: #49b295;
    color: white;
    margin-left: 4vw;
    margin-right: 4vw;
    margin-bottom: -1px;
    margin-top: -1px;
}

.prayertime img{
    height: auto;
    width: 3vw;
    position: absolute;
    right: 1vw;
    bottom: 0;
    top: 0;
    margin: auto;
}

.prayertime .selected img{
    width: 4vw;
    right: 2vw;
}